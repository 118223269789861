<template>
    <main class="contacts">
        <app-banner :i18n="i18n">
            <h1 class="accent-size accent-stroke text-lowercase text-center">{{ i18n.pages.contacts.banner }}</h1>
            <p>
                <a href="mailto:office@imagemakers.bg">office@imagemakers.bg</a>
                <a href="tel:+35929502050">+359 2 950 20 50</a>
                <a href="tel:+359899878880">+359 899 87 88 80</a>
                <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/Imagemakers.BG/">Facebook</a>
                <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCRqaB-7bBcJFflk8CJrjj0g/videos">YouTube</a>
            </p>
        </app-banner>

        <app-background 
            @backgroundLoaded="setBackgroundLoaded"
            url="/images/contacts.jpg" 
        />
    </main>
</template>

<script>
import AppBanner from '../components/AppBanner.vue'
import AppBackground from '../components/AppBackground.vue'

export default {
components: {
    'app-banner': AppBanner,
    'app-background': AppBackground,
},
props: ['i18n'],
emits: ['ready', 'title'],
data() {
    return {
        backgroundLoaded: false
    }
},
methods: {
    setBackgroundLoaded(value) {
        this.backgroundLoaded = value
        
        if (this.backgroundLoaded) {
            this.$emit('ready', true)
            this.$emit('title', this.i18n.menu.contacts)
        }
    }
},
}
</script>

<style lang="scss">
.contacts {
    text-align: center;

    a {
        font-size: 1rem;
        margin-right: 0;
        margin-bottom: 1rem;
        display: block;
        text-decoration: none;

        &:last-child {
            margin-right: 0;
        }

        @include breakpoint-md {
            display: inline-block;
            margin-right: 2rem;
            margin-bottom: 0;
        }
    }
}
</style>
