<template>
    <transition name="fade">
        <app-loading v-if="!pageReady || !fontsReady" />
    </transition>

    <app-header :i18n="i18n" />

    <router-view 
        @ready="setPageReady"
        @title="setPageTitle"
        :i18n="i18n"
        v-slot="{ Component, route }"
    >
        <transition name="fade" mode="out-in">
            <component :class="{'animate': pageReady && fontsReady}" :is="Component" :key="route.fullPath" />
        </transition>
    </router-view>
</template>

<script>
import bg from './i18n/bg.json'
import en from './i18n/en.json'

import fontfaceobserver from 'fontfaceobserver'

import AppLoading from './components/AppLoading.vue'
import AppHeader from './components/AppHeader.vue'

export default {
data() {
    return {
        pageReady: false,
        fontsReady: false,
    }
},
components: {
    'app-loading': AppLoading,
    'app-header': AppHeader,
},
mounted() {
    var fontA = new fontfaceobserver('Ropa Soft Pro Regular');
    var fontB = new fontfaceobserver('Ropa Soft Pro Extra Bold');
    Promise.all([fontA.load(null, 200000), fontB.load(null, 200000)])
    .then(() => {
        this.fontsReady = true
    });
},
computed: {
    i18n() {
        this.translateDescription()

        if (this.$route.params.locale == 'en') {
            return en
        } else {
            return bg
        }
    }
},
watch: {
    '$route' () {
        this.pageReady = false
    }
},
methods: {
    setPageReady(value) {
        this.pageReady = value
    },
    setPageTitle(value) {
        document.title = value + ' | Imagemakers Creative Studio'
    },
    translateDescription() {      
        const description = document.head.querySelector('meta[name=description]')

        if (this.$route.params.locale == 'en') {
            description.content = 'Well-thought-out and precisely executed services in the field of advertising, marketing and web design'
        } else {
            description.content = 'Добре обмислени и прецизно изпълнени услуги в областта на рекламата, маркетинга и уеб-дизайна'
        }
    }
}
}
</script>

<style lang="scss">
@import "assets/styles/vendor/normalize.css";

html {
    box-sizing: border-box;
    background: $black;
}

*, *:before, *:after {
    box-sizing: inherit;
}

a {
    text-decoration: inherit;
    color: $white;
}

input, button { 
    border: none; 
    outline: none;
    background: none;
    color: $white;
    letter-spacing: inherit;
    padding: 0;
} 

button {
    cursor: pointer;
}

a, button {
    transition: 0.5s;
}

a:hover, button:hover {
    color: $main-color;
}

html {
    font-family: $font-main-regular;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    line-height: 1.2;
    word-spacing: 0.1em;
    letter-spacing: 0.025em;
    color: $white;

    @include breakpoint-lg {
        font-size: calc(1rem + (22 - 16) * ((100vw - 992px) / (1600 - 992)));
        min-height: 0vw;
    }

     @include breakpoint-wide {
        font-size: 22px;
    }

    *::selection {
        background: $main-color;
        color: $white;
    }
} 

// Type
p {
    line-height: 1.3;
}

h1 {
    font-size: 3rem;
    margin: 2.5rem auto;
    line-height: 1.1;
    padding: $spacing;
    width: 100%;
    text-align: center;

    @include breakpoint-lg {
        padding: 0 29vw;
        font-size: 5vw;
    }
}

h2 {
    font-size: 1.8rem;
    margin: 2.5rem auto;
}

h1, h2, h3 {
    font-weight: normal;
}

// Aligns
.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.vertical-center {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

// Lowercase
.text-lowercase {
    text-transform: lowercase;
}

// Accent
.accent {
    color: $main-color;
}

.accent-stroke {
    color: $main-color;
    font-family: $font-main-bold;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 0.15rem;
    -webkit-text-stroke-color: $main-color;
}

.accent-size {
    padding: 0;
    font-size: 5rem;
    line-height: 1;
    width: auto;

    @include breakpoint-lg {
        // font-size: 6rem;
        font-size: 10vw;
    }
}

// Animations
@keyframes fadeUp {
    from {
        opacity: 0;
        transform: translate3d(0, 100px, 0);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeLeft {
    from {
        opacity: 0;
        transform: translate3d(100px, 0, 0);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

// Transitions
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}


.slide-fade-enter-active {
  transition: all 0.5s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

</style>
