<template>
    <div class="banner" :class="{'banner--hidden': !isScrolledToTop && fullScreenPages}">
        <div class="banner__container" :class="{' banner__container--fixed': fullScreenPages }">
            <div class="banner__padding">
                <slot></slot>
            </div>
        </div>

        <div v-if="fullScreenPages" class="scroll">
            <span>{{ i18n.labels.scroll }}</span>
        </div>

        <div v-if="fullScreenPages && aboutPage" class="share">
            <transition name="fade" mode="out-in">
                <button v-if="!shareToggleClicked" @click="shareToggleClicked = true">
                    <svg class="share__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 108.33"><path class="st0" d="M79.17 66.67c-7.38 0-13.82 3.85-17.52 9.63L39.34 63.55c1.44-2.83 2.33-5.99 2.33-9.38 0-2.1-.4-4.08-.98-5.99L63.9 34.92c3.8 4.13 9.21 6.75 15.27 6.75 11.5 0 20.83-9.33 20.83-20.83S90.67 0 79.17 0 58.33 9.33 58.33 20.83c0 2.1.4 4.08.98 5.99L36.1 40.09c-3.8-4.13-9.21-6.75-15.27-6.75C9.33 33.33 0 42.66 0 54.17 0 65.67 9.33 75 20.83 75c4.75 0 9.07-1.65 12.58-4.33l-.08.16 25.23 14.42c-.08.75-.23 1.48-.23 2.25 0 11.5 9.33 20.83 20.83 20.83S100 99 100 87.5s-9.33-20.83-20.83-20.83zm0-58.34c6.9 0 12.5 5.6 12.5 12.5s-5.6 12.5-12.5 12.5-12.5-5.6-12.5-12.5 5.59-12.5 12.5-12.5zM20.83 66.67c-6.9 0-12.5-5.6-12.5-12.5s5.6-12.5 12.5-12.5 12.5 5.6 12.5 12.5-5.59 12.5-12.5 12.5zM79.17 100c-6.9 0-12.5-5.6-12.5-12.5S72.26 75 79.17 75c6.9 0 12.5 5.6 12.5 12.5s-5.6 12.5-12.5 12.5z"/></svg>
                    <span class="share__label">{{ i18n.labels.share }}</span>
                </button>
                <div v-else>
                    <div class="share__links">
                        <a :href="'http://www.linkedin.com/shareArticle?mini=true&amp;url=https://imagemakers.bg' + $route.path" target="_blank">
                            <svg viewBox="0 0 800 800" xmlns="http://www.w3.org/2000/svg"><path d="M268 629h-97V319h97zm157 0h-97V319h93v42h1q31-50 93-50 114 0 114 133v185h-96V466q0-70-49-70-59 0-59 69z"/><circle cx="219" cy="220" r="56"/></svg>
                        </a>
                        <a :href="'http://www.facebook.com/sharer.php?u=https://imagemakers.bg' + $route.path" target="_blank">
                            <svg viewBox="0 0 800 800" xmlns="http://www.w3.org/2000/svg"><path d="M445 643h-90V419h-75v-87h75v-64q0-55 32-86 30-29 80-29 28 0 67 3v78h-47q-42 0-42 38v60h86l-11 87h-75v224z"/></svg>
                        </a>
                        <a :href="'https://twitter.com/share?url=https://imagemakers.bg' + $route.path" target="_blank">
                            <svg viewBox="0 0 800 800" xmlns="http://www.w3.org/2000/svg"><path d="M679 239s-21 34-55 57c7 156-107 329-314 329-103 0-169-50-169-50s81 17 163-45c-83-5-103-77-103-77s23 6 50-2c-93-23-89-110-89-110s23 14 50 14c-84-65-34-148-34-148s76 107 228 116c-22-121 117-177 188-101 37-6 71-27 71-27s-12 41-49 61c30-2 63-17 63-17z"/></svg>
                        </a>
                    </div>         
                </div>
            </transition>
        </div>

    </div>
</template>

<script>
export default {
props: ['i18n'],
data() {
    return {
        isScrolledToTop: true,
        shareToggleClicked: false,
    }
},
created() {
    document.addEventListener('scroll', this.scrolledToTop)
},
beforeUnmount() {
    document.removeEventListener('scroll', this.scrolledToTop)
},
computed: {
    fullScreenPages() {
        if (
            this.$route.name != 'Contacts' && 
            this.$route.name != 'PageNotFound' && 
            this.$route.name != 'Services'
        ) {
            return true
        }
    },
    aboutPage() {
        if (this.$route.name != 'About') {
            return true
        }
    }
},
methods: {
    scrolledToTop() {
        if(!window.pageYOffset) {
            this.isScrolledToTop = true
        } else {
            this.isScrolledToTop = false
        }
    }
}
}
</script>

<style lang="scss">
.banner {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: opacity 1s;
    opacity: 1;

    &--hidden {
        opacity: 0;
        pointer-events: none;
    }

    &__fixed {
        position: fixed;
        width: 100%;
        left: 0;
    }

    &__container {
        width: 100%;
        max-height: 100%;

        &--fixed {
            position: fixed;
            width: 100%;
            left: 0;
        }
    }

    &__padding {
        padding: 100px 0;
    }
}

.scroll {
    position: fixed;
    bottom: 5vw;
    display: none;

    @include breakpoint-sm {
        display: block;
    }  

    span {
        font-size: 0.7rem;
        opacity: 0.4;
        text-transform: lowercase;
    }
}

.share {
    position: fixed;
    bottom: $spacing;
    right: $spacing;

    button {
        display: flex;
        align-items: center;
    }

    &__links {
        a {
            display: block;
            opacity: 0;
            margin-top: 15px;
            animation-duration: 1s;
            animation-timing-function: ease;
            animation-fill-mode: forwards;
            animation-name: fadeUp;
            backface-visibility: hidden;

            svg {
                width: 35px;
                fill: white;
                transition: 0.5s;

                &:hover {
                    fill: $main-color;
                }
            }
        }

        @for $i from 1 through 3 {
            a:nth-of-type(#{$i}n) {
                animation-delay: #{$i * 0.1}s;
            }
        }
    }

    &__label {
        text-transform: lowercase;
    }

    &__icon {
        display: inline-block;
        width: 30px;
        margin-right: 14px;

        path {
            fill: $main-color;
        }
    }
}

.animate .banner {
    h1, p, .scroll, .share {
        opacity: 0;
        animation-delay: 0.5s;
        animation-duration: 2s;
        animation-timing-function: $easeOutQuart;
        animation-fill-mode: forwards;
        animation-name: fadeUp;
        backface-visibility: hidden;
    }

    .scroll, .share {
        animation-name: fadeIn;
        animation-delay: 1.5s;
    }

    p {
        animation-delay: 1s;
    }
}
</style>
