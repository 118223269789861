<template>
    <div class="loading loading--center">
        <svg ref="spinner" class="loading__spinner" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="45"/>
        </svg>
    </div>

</template>

<script>
export default {
mounted() {
    setTimeout(() => {
        if (this.$refs.spinner) {
            this.$refs.spinner.style.opacity = '1'
        }
    }, 3000);

    document.documentElement.style.overflow = 'hidden'
},
beforeUnmount() {
    document.documentElement.style.overflow = 'auto'
}
}
</script>

<style lang="scss">
.loading {
    position: fixed !important;
    z-index: 999998;
    top: 0;
    left: 0;

    &--center {
        background-color: black;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__spinner {
        opacity: 0;
        transition: opacity 1s;
        position: absolute;
        animation: 1.25s linear infinite svg-animation;
        max-width: 100px;

        circle {
            animation: 1.25s ease-in-out infinite both circle-animation;
            display: block;
            fill: transparent;
            stroke: $main-color;
            stroke-linecap: round;
            stroke-dasharray: 283;
            stroke-dashoffset: 280;
            stroke-width: 10px;
            transform-origin: 50% 50%;
        }
    }
}

@keyframes svg-animation {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg)
  }
}

@keyframes circle-animation {
    0%,
    25% {
        stroke-dashoffset: 280;
        transform: rotate(0);
    }

    50%,
    75% {
        stroke-dashoffset: 75;
        transform: rotate(45deg);
    }

    100% {
        stroke-dashoffset: 280;
        transform: rotate(360deg);
    }
}
</style>