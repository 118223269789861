const fetchItems = () => ({
    data() {
        return {
            item: '',
            itemFetched: false
        }
    },
    created() {
        let url

        if (process.env.NODE_ENV == 'development') {
            url = `https://api.imagemakers.test${this.$route.path}`
        } else {
            url = `https://api.imagemakers.bg${this.$route.path}`
        }

        fetch(url)
        .then(response => response.json())
        .then(data => {
            if (data) {
                this.itemFetched = true
                this.item = data
                this.emit()
            } else {
                throw new Error  
            }
        })
        .catch(error => {
            this.$router.replace({ 
                name: 'PageNotFound',
                params: { locale: 'bg', pathMatch: this.$route.path.substring(1).split('/') },
                query: this.$router.query,
                hash: this.$router.hash,
            })
        })
    }
})

export default fetchItems