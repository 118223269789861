<template>
    <main>
        <app-banner :i18n="i18n">
            <h1 class="accent-size accent-stroke text-lowercase text-center">404</h1>
        </app-banner>

        <app-background 
            @backgroundLoaded="setBackgroundLoaded"
            url="/images/imagemakers-behind-the-scenes.jpg" 
        />
    </main>
</template>

<script>
import AppBanner from '../components/AppBanner.vue'
import AppBackground from '../components/AppBackground.vue'

export default {
components: {
    'app-banner': AppBanner,
    'app-background': AppBackground,
},
props: ['i18n'],
emits: ['ready', 'title'],
data() {
    return {
        backgroundLoaded: false
    }
},
methods: {
    setBackgroundLoaded(value) {
        this.backgroundLoaded = value

        if (this.backgroundLoaded) {
            this.$emit('ready', true)
            this.$emit('title', '404')
        }
    }
},
}
</script>