<template>
    <main>
        <app-banner :i18n="i18n">
            <h1>{{ item.title }}</h1>
        </app-banner>

        <app-content v-if="item">
            <div v-html="item.content"></div>
        </app-content>

        <app-background v-if="item"
            @backgroundLoaded="setBackgroundLoaded"
            :url="item.background"
        />
        <app-footer :i18n="i18n" :item="item" />
    </main>
</template>

<script>
import fetchItems from '../mixins/fetchItems'

import AppBanner from '../components/AppBanner.vue'
import AppContent from '../components/AppContent.vue'
import AppBackground from '../components/AppBackground.vue'
import AppFooter from '../components/AppFooter.vue'

export default {
components: {
    'app-banner': AppBanner,
    'app-content': AppContent,
    'app-background': AppBackground,
    'app-footer': AppFooter,
},
mixins: [
    fetchItems(),
],
props: ['i18n'],
emits: ['ready', 'title'],
data() {
    return {
        backgroundLoaded: false,
    }
},
methods: {
    setBackgroundLoaded(value) {
        this.backgroundLoaded = value
        this.emit()
    },
    emit() {
        if (this.itemFetched && this.backgroundLoaded) {
            this.$emit('ready', true)
            this.$emit('title', this.item.title)
        }
    }
},
}
</script>

<style lang="scss">

</style>
