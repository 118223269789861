<template>
    <main>
        <div v-if="itemFetched">
            <app-banner :i18n="i18n">
                <div class="services">

                    <div class="services__sections" v-for="(section, index) in item.sections" :key="section">
                        <h2 :style="{ animationDelay: 0.5 + index * 0.075 + 's' }">
                            {{ section.title }}
                        </h2>

                        <template v-for="(service, index) in item.services" :key="service.slug">
                            <template v-if="service.section == section.id">
                                <div 
                                    class="services__section"
                                    :style="{ animationDelay: 0.5 + index * 0.075 + 's' }"
                                >
                                    <router-link
                                        active-class="active"
                                        :to="{ name: 'Service', params: { locale: $route.params.locale, slug: service.slug}}"
                                    >
                                        {{ service.title }}
                                    </router-link>
                                </div> 
                            </template> 
                            <template v-else></template>
                        </template>
                            
                    </div>
                </div>
            </app-banner>

            <app-background 
                @backgroundLoaded="setBackgroundLoaded"
                url="/images/services.jpg" 
            />
        </div>
    </main>
</template>

<script>
import fetchItems from '../mixins/fetchItems'

import AppBanner from '../components/AppBanner.vue'
import AppBackground from '../components/AppBackground.vue'

export default {
components: {
    'app-banner': AppBanner,
    'app-background': AppBackground,
},
mixins: [
    fetchItems(),
],
props: ['i18n'],
emits: ['ready', 'title'],
data() {
    return {
        backgroundLoaded: false,
    }
},
methods: {
    setBackgroundLoaded(value) {
        this.backgroundLoaded = value
        this.emit()
    },
    emit() {
        if (this.itemFetched && this.backgroundLoaded) {
            this.$emit('ready', true)
            this.$emit('title', this.i18n.menu.services)
        }
    }
},
}
</script>

<style lang="scss">
.services {
    text-align: center;
    max-width: 1100px;
    margin: auto;

    @include breakpoint-lg {
        display: flex;
    }

    h2 {
        font-family: $font-main-bold;
        color: $main-color;
        text-transform: lowercase;
        margin: 2rem 0;
        font-size: 3rem;
    }

    &__all {
        display: block;
        margin: 2.5rem auto;

        @include breakpoint-lg {
            margin: auto;
        }
    }

    &__section {
        margin-bottom: 1rem;
    }

    &__sections {
        width: 100%;

        @include breakpoint-lg {
            width: 33.3%;
            padding: 0 15px;
            float: left;
        }

        &:nth-child(3) {
            padding: 0;
        }
    }
}

.services a {  
    display: inline-block;
    transition: 0.5s;
    pointer-events: auto;
}

.services a:hover {  
    color: white;
}

.services:hover a:not(:hover) {  
    opacity: 0.4;
}

.services {  
    pointer-events: none;
}

.animate {
    .services__sections h2, .services__section {
        opacity: 0;
        animation-duration: 2s;
        animation-timing-function: $easeOutQuart;
        animation-fill-mode: forwards;
        animation-name: fadeUp;
    }
}
</style>
