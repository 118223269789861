import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Contacts from '../views/Contacts.vue'
import Services from '../views/Services.vue'
import Service from '../views/Service.vue'
import Project from '../views/Project.vue'
import PageNotFound from '../views/PageNotFound.vue'

    const routes = [
    {
        path: '',
        redirect: '/bg',
    },
    {
        path: '/:locale',
        name: 'Home',
        component: Home,
    },
    {
        path: '/:locale/about',
        name: 'About',
        component: About,
    },
    {
        path: '/:locale/contacts',
        name: 'Contacts',
        component: Contacts,
    },
    {
        path: '/:locale/services',
        name: 'Services',
        component: Services,
    },
    {
        path: '/:locale/services/:slug',
        name: 'Service',
        component: Service,
    },
    {
        path: '/:locale/work/:slug',
        name: 'Project',
        component: Project,
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'PageNotFound',
        component: PageNotFound,
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve({ left: 0, top: 0 })
            }, 500)
        })
    },
})

router.beforeEach((to, from, next) => {
    const locales = ['bg', 'en']
    
    if (locales.includes(to.params.locale)) {
        next()
    } else {
        router.replace({ 
            name: 'PageNotFound',
            params: { locale: 'bg', pathMatch: to.path.substring(1).split('/') },
            query: router.query,
            hash: router.hash,
        })
    }
})

export default router
