<template>
    <!-- <div ref="background" class="background"> -->
    <div ref="background" class="background" :class="{'background--hidden': !isScrolledToTop}">
        <div class="background__overlay"></div>
        <div ref="backgroundMedia" class="background__media" :style="{ backgroundImage: `url('${url}')` }"></div>
    </div>
</template>

<script>
export default {
props: ['url'],
emits: ['backgroundLoaded'],
data() {
    return {
        isScrolledToTop: true,
    }
},
created() {
    this.loadBackgroundImage()
    document.addEventListener('scroll', this.scrolledToTop)
},
mounted() {
    if (this.$route.name == 'About') {
        this.$refs.backgroundMedia.style.backgroundPosition = '30% 50%'
    }
    if (this.$route.name == 'Service') {
        this.$refs.backgroundMedia.style.backgroundPosition = '80% 50%'
    }
},
beforeUnmount() {
    document.removeEventListener('scroll', this.scrolledToTop)
},
methods: {
    loadBackgroundImage() {
        this.$emit('backgroundLoaded', false)

        let image = new Image();
        image.src = this.url
        image.onload = () => {
            this.$emit('backgroundLoaded', true)
        }
    },
    scrolledToTop() {
        if(!window.pageYOffset) {
            this.isScrolledToTop = true
        } else {
            this.isScrolledToTop = false
        }
    }
}
}
</script>

<style lang="scss">
.background {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%; 
    transition: opacity 1s, transform 4s ease;

    &--hidden {
        transform: scale(1.2);
        opacity: 0;
    }

    &__overlay {
        height: 100%;
        width: 100%; 
        background: linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.7) 75%, rgba(0,0,0,0.8) 100%);
    }

    &__media {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%; 
        z-index: -1;
        background-size: cover;
        background-position: 50% 50%;
    }
}
</style>