<template>
    <main>
        <app-banner :i18n="i18n">
            <h1>{{ item.title }}</h1>
        </app-banner>

        <app-content v-if="item">
            <div v-html="item.content"></div>
        </app-content>

        <div v-if="item" class="projects">
            <div class="grid">
                <div class="col-6 project" v-for="project in item.projects" :key="project.slug">
                    <router-link
                        active-class="active"
                        :to="{ name: 'Project', params: { locale: $route.params.locale, slug: project.slug }}"
                    >
                        <div class="project__background" :style="{ backgroundImage: `url(${project.thumb})` }"></div>
                            <h3>{{ project.title }}</h3>
                        <div class="explore">{{ i18n.labels.explore }}</div>
                    </router-link>
                </div>
            </div>
        </div>

        <app-background 
            @backgroundLoaded="setBackgroundLoaded"
            url="/images/service.jpg" 
        />

        <app-footer :i18n="i18n" :item="item" />
    </main>
</template>

<script>
import fetchItems from '../mixins/fetchItems'

import AppBanner from '../components/AppBanner.vue'
import AppContent from '../components/AppContent.vue'
import AppBackground from '../components/AppBackground.vue'
import AppFooter from '../components/AppFooter.vue'

export default {
components: {
    'app-banner': AppBanner,
    'app-content': AppContent,
    'app-background': AppBackground,
    'app-footer': AppFooter,
},
mixins: [
    fetchItems(),
],
props: ['i18n'],
emits: ['ready', 'title'],
data() {
    return {
        backgroundLoaded: false,
    }
},
methods: {
    setBackgroundLoaded(value) {
        this.backgroundLoaded = value
        this.emit()
    },
    emit() {
        if (this.itemFetched && this.backgroundLoaded) {
            this.$emit('ready', true)
            this.$emit('title', this.item.title)
        }
    }
},
}
</script>

<style lang="scss">
.projects {
    padding: 0 $spacing;
    width: 100%;
    margin: $spacing * 2 auto;

    &__more {
        text-align: center;
        padding: $spacing * 2;

        &-title {
            font-family: $font-main-bold;
            font-weight: normal;
            font-size: 3rem;
            line-height: 1;
            margin: 2rem 0;
            margin-top: 0;

            @include breakpoint-lg {
                font-size: 5rem;
            }
        }
    }
}

.project {
    position: relative;
    margin-bottom: $spacing * 2 !important;

    &:nth-child(even) {
        top: 0vw;

        @include breakpoint-lg {
            top: $spacing;
        }
    }

    &:hover .project__background {
        transform: translateY(-10px);
        transition: transform 0.5s;
    }

    &:hover a {
        color: white;
    }

    .explore {
        opacity: 0.5;
        text-transform: lowercase;
    
        &:before {
            content: '';
            background: $white;
            display: inline-block;
            width: 2.2rem;
            height: 2px;
            margin: 0 0.6rem 0.25rem 0;
        }
    }

    &__background {
        background-color: #272727;
        border-radius: 5px;
        transform: translateY(0);
        transition: transform 0.5s;
        background-size: cover;
        background-position: 50% 50%;
        width: 100%;
        padding-bottom: 60%;
    }
}

 // Grid specs
 $unit: vw;
 $columns-spacing: 5;
 $columns: 12;

 // Grid container
.grid {
    display: flex;
    flex-flow: row wrap;
    margin-left: -$columns-spacing / 2 + $unit;
    margin-right: -$columns-spacing / 2 + $unit;
    margin-bottom: -$columns-spacing + $unit;
}

//  Columns spacing
 %flex-col {
    margin: 0 ($columns-spacing / 2 + $unit) ($columns-spacing + $unit);
    margin-bottom: $columns-spacing * 2 + $unit;

    @include breakpoint-lg {
        margin-bottom: $columns-spacing + $unit;
    }

}

 // Columns
@for $i from 1 through $columns {
    .col-#{$i} {
        flex-basis: 100%;
        @extend %flex-col;

        @include breakpoint-lg {
            flex-basis: calc(#{100% / $columns * $i} - #{ $columns-spacing}#{$unit});
        }
    }
}
</style>
