<template>
    <div class="page-next text-center">
        <template v-if="item && item.next">
            <p class="page-next__label">{{ i18n.labels.next }}</p>
            <div class="page-next__title">
                <router-link
                    :to="{ name: 'Project', params: { locale: $route.params.locale, slug: item.next.slug }}"
                >
                {{ item.next.title }}
                </router-link>
            </div>
        </template>
        <template v-else>
             <div class="accent-size text-lowercase text-center">
                <router-link
                    :to="{ name: 'Contacts', params: { locale: $route.params.locale }}"
                >
                    <span class="accent-stroke">{{ i18n.pages.contacts.banner }}</span>
                </router-link>
            </div>
        </template>
    </div>

    <footer class="footer">
        <div class="footer__copyright">© {{ currentYear }}</div>
        <button @click="scrollTop(0)" class="footer__to-top">{{ i18n.labels.scrollTop }}</button>
    </footer>
</template>

<script>
export default {
    name: 'AppFooter',
    props: ['i18n', 'item'],
    data() {
        return {
            currentYear: new Date().getFullYear()
        }
    },
    methods: {
        scrollTop(to, duration=1000) {
        //t = current time
        //b = start value
        //c = change in value
        //d = duration
        const easeInOutQuad = function (t, b, c, d) {
            t /= d/2;
            if (t < 1) return c/2*t*t + b;
            t--;
            return -c/2 * (t*(t-2) - 1) + b;
        };

        return new Promise((resolve, reject) => {
            const element = document.scrollingElement;

            if (typeof to === 'string') {
            to = document.querySelector(to) || reject();
            }
            if (typeof to !== 'number') {
            to = to.getBoundingClientRect().top + element.scrollTop;
            }

            let start = element.scrollTop,
                change = to - start,
                currentTime = 0,
                increment = 20;

            const animateScroll = function() {
                currentTime += increment;
                let val = easeInOutQuad(currentTime, start, change, duration);
                element.scrollTop = val;
                if(currentTime < duration) {
                    setTimeout(animateScroll, increment);
                } else {
                resolve();
                }
            };
            animateScroll();
        });
        }
    }
}
</script>

<style lang="scss">
footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 $spacing $spacing $spacing;
    font-size: 0.7rem;

    div, button {
        text-transform: lowercase;
    }
}

.page-next {
    margin: $spacing * 2 0;
    padding: 0 $spacing;

    &__title {
        font-weight: normal;
        font-size: 3rem;
        line-height: 1.1;
        padding: $spacing;
        width: 100%;
        text-align: center;
        padding: 0 5vw;

        @include breakpoint-lg {
            padding: 0 29vw;
        }
    }

    span {
        transition: 0.5s;

        &:hover {
            -webkit-text-stroke-color: white;
        }
    }
}
</style>
