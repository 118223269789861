<template>
    <div class="content">
        <transition name="fade">
            <div @click="close" class="content__video-overlay" v-if="isActive">
                <div @click="close" class="close">
                    <span class="close__line close__line-1"></span>
                    <span class="close__line close__line-2"></span>
                </div>
                <div ref="iframe" class="content__video-iframe"></div>
            </div>
        </transition>

        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'AppContent',
    data() {
        return {
            isActive: false
        }
    },
    created() {
        document.addEventListener('keyup', this.closeToggleOnEscape)
    },
    mounted() {
        this.getImages()
    },
    beforeUnmount() {
        document.removeEventListener('keyup', this.closeToggleOnEscape)
    },
    methods: {
        getImages() {
            let images = document.getElementsByTagName('img')

            // Wide images
            let wide = Array.from(images).filter(image => image.src.endsWith('-wide.jpg'))
            wide.forEach(image => {
                image.classList.add('image--wide')
            }); 
            
            // Youtube videos
            let videos = Array.from(images).filter(image => image.src.endsWith('-thumb.jpg'))
            videos.forEach(image => {

                let videoParent = document.createElement('div');
                videoParent.classList.add('content__video')

                let playButton = document.createElement('div');
                playButton.classList.add('content__video-play')

                image.parentNode.insertBefore(videoParent, image);
                videoParent.appendChild(playButton);
                videoParent.appendChild(image);

                let id = image.src.substring(0, image.src.indexOf('-thumb.jpg')).substring(image.src.lastIndexOf('/') + 1);

                image.addEventListener('click', event => {
                    this.isActive = true
                    
                    this.$nextTick(function () {
                        this.$refs.iframe.innerHTML = `<iframe width="1520" height="685" src="https://www.youtube.com/embed/${id}?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
                    })
                })
            }); 
        },
        close() {
            this.isActive = false 
            // this.$refs.iframe.innerHTML = ''  
        },
       toggle() {
            this.isActive = true
       },
       closeToggleOnEscape(evt) {
           if (evt.keyCode == 27) {
               this.isActive = false
           }
       }
    }
}
</script>

<style lang="scss">
.content {
    p, h2 {
        max-width: 960px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding: 0 $spacing;

        @include breakpoint-md {
            width: 50vw;
            padding: 0;
        }

        a {
            text-decoration: underline;
        }
    }

    img {
        width: 100%;
        height: auto;
        margin: $spacing * 2 auto;
        display: block;
        padding: 0 $spacing;

        @include breakpoint-md {
            width: calc(100vw - 20vw);
            margin: $spacing * 2 auto;
            padding: 0;
        }
    }

    .image--wide {
        width: 100%;
        margin: $spacing * 2 0;
    }

    &__video {
        position: relative;

        img {
            cursor: pointer;
            opacity: 0.6;
        }

        &-play {
            pointer-events: none;
            position: absolute;
            z-index: 1;
            background-image: url(/images/play.svg);
            background-size: contain;
            background-repeat: no-repeat;
            height: 4rem;
            width: 4rem;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            @include breakpoint-lg {
                height: 8rem;
                width: 8rem;
            }
        }

        &-overlay {
            position: fixed;
            width: 100%;
            height: 100%;
            z-index: 9999;
            top: 0;
            left: 0;
            background: rgba(13, 13, 13, 0.9);
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &-iframe {
            iframe {
                width: calc(100vw - 22.19121vw);
                height: calc(100vh - 7.39707vw);
                max-height: calc((100vw - 22.19121vw) * 9/16);
                max-width: calc((100vh - 7.39707vw) * 16/9);
            }
        }
    }
}

.close {
    width: 40px;
    height: 23px;
    display: block;
    position: fixed;
    top: 40px;
    right: 40px;
    transition: 0.5s;
    cursor: pointer;
        
    &:hover {
        opacity: 0.4;
    }

    &__line {
        position: absolute;
        height: 7px;
        width: 100%;
        background: $white;
        border-radius: 1em;

        &-1 {
            transform:rotate(45deg);
            top: 36%;
            width: 100%;
        }

        &-2 {
            transform:rotate(-45deg);
            bottom: 36%;
        }
    }
}
</style>