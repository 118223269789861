<template>
    <main ref="home" class="home">
        <div class="home__bottom">
            <div class="home__bottom-container">
                <div class="home__content">
                    <h1 v-html="i18n.pages.home.years"></h1>
                    <p v-html="i18n.pages.home.description"></p>
                    <div class="home__contacts">
                        <a href="mailto:office@imagemakers.bg">office@imagemakers.bg</a>
                        <a href="tel:+35929502050">+359 2 950 20 50</a>
                        <a href="tel:+359899878880">+359 899 87 88 80</a>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/Imagemakers.BG/">Facebook</a>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCRqaB-7bBcJFflk8CJrjj0g/videos">YouTube</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="home__overlay"></div>
        <video ref="video" class="home__video" muted playsinline loop src="/images/video.mp4"></video>
    </main>
</template>

<script>
export default {
props: ['i18n'],
emits: ['ready', 'title'],
data() {
    return {
        backgroundLoaded: false
    }
},
mounted() {
    if (this.$route.params.locale == 'en') {
        document.querySelector('.home h1').style.maxWidth = '12em'
    }

    this.$emit('ready', true)
    this.$emit('title', this.i18n.menu.home)
},
updated() {
    if (this.$refs.home.classList.contains('animate')) {
        this.playVideo()
    }
},
methods: {
    playVideo() {
        const video = this.$refs.video
        setTimeout(function(){
            video.play()
        }, 4000);
    }
},
}
</script>

<style lang="scss">
.home {

    &__bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: flex-end;

        &-container {
            width: 100%;
            max-height: 100%;
        }
    }

    &__content {
        overflow: hidden;
        padding: $spacing;
        padding-top: 100px;
    }

    h1 {
        font-size: 2rem;
        line-height: 1;
        margin: 1.8rem 0;
        padding: 0;
        text-align: left;
        max-width: 15em;

        @include breakpoint-md {
            font-size: 2.5rem;
        }
    }

    p {
        max-width: 33.5em;
    }

    &__years {
        display: block;
        font-size: 5rem;
        margin-bottom: 2.2rem;
        line-height: 0.7;

        @include breakpoint-md {
            font-size: 7.5rem;
        }
    }

    &__description {
        display: block;

        &--accent {
            color: $main-color;
        }
    }

    &__contacts {
        margin-top: 1.8rem;
        
        a {
            font-size: 1rem;
            margin-right: 2rem;
            margin-bottom: 0.75rem;
            display: block;
            color: rgba(255, 255, 255, 0.5);

            &:hover {
                color: $main-color;
            }

            &:last-child {
                margin-right: 0;
            }

            @include breakpoint-md {
                display: inline-block;
                margin-bottom: 0;
                font-size: 0.7rem;
            }
        }
    }

    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 40%, rgba(0, 0, 0, 0.8) 100%)
    }

    &__video {
        position: fixed;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: -2;
        transform: translate(-50%, -50%);
    }
}

.animate .home__content {
    .home__years,
    .home__description,
    .home__contacts a,
    p {
        opacity: 0;
        animation-delay: 0.5s;
        animation-duration: 2s;
        animation-timing-function: $easeOutQuart;
        animation-fill-mode: forwards;
        animation-name: fadeUp;
    }

    .home__description {
        animation-delay: 1s;
    }

    p {
        animation-delay: 1.5s;
    }

    .home__contacts a {
        animation-name: fadeLeft;
        transition: color 0.5s;

        &:nth-of-type(1) {
            animation-delay: 2.5s;
        }
        &:nth-of-type(2) {
            animation-delay: 2.75s;
        }
        &:nth-of-type(3) {
            animation-delay: 3s;
        }
        &:nth-of-type(4) {
            animation-delay: 3.25s;
        }
        &:nth-of-type(5) {
            animation-delay: 3.5s;
        }
    }
}
</style>