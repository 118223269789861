<template>
    <main>
        <app-banner :i18n="i18n">
            <h1 class="accent-size accent-stroke text-lowercase text-center">{{ i18n.pages.about.banner }}</h1>
        </app-banner>

        <app-content>
            <div v-html="i18n.pages.about.content"></div>
        </app-content>

        <div class="clients text-center">
            <img src="/images/clients/weber.svg" alt="">
            <img src="/images/clients/dcp.svg" alt="">
            <img src="/images/clients/knauf.svg" alt="">
            <img src="/images/clients/saveto.svg" alt="">
            <img src="/images/clients/hidrobeton.svg" alt="">
        </div>

        <app-background 
            @backgroundLoaded="setBackgroundLoaded"
            url="/images/about.jpg" 
        />

        <app-footer :i18n="i18n" />
    </main>
</template>

<script>
import AppBanner from '../components/AppBanner.vue'
import AppContent from '../components/AppContent.vue'
import AppBackground from '../components/AppBackground.vue'
import AppFooter from '../components/AppFooter.vue'

export default {
components: {
    'app-banner': AppBanner,
    'app-content': AppContent,
    'app-background': AppBackground,
    'app-footer': AppFooter,
},
props: ['i18n'],
emits: ['ready', 'title'],
data() {
    return {
        backgroundLoaded: false,
    }
},
methods: {
    setBackgroundLoaded(value) {
        this.backgroundLoaded = value

        if (this.backgroundLoaded) {
            this.$emit('ready', true)
            this.$emit('title', this.i18n.menu.about)
        }
    }
},
}
</script>

<style lang="scss">
.clients {
    margin: $spacing * 2 auto;
    padding: $spacing * 2;
    background: linear-gradient(120deg,#1e1e1e,#0a0a0a 70%);

    img {
        transition: opacity 0.5s;
        opacity: 0.5;
        padding: 0;
        height: 5vw * 2;
        max-width: 15vw * 2;
        padding: 0 3vw;
        display: block;
        margin: 0 auto;
        margin-bottom: 2.5rem;
        margin-top: 2.5rem;

        &:hover {
            opacity: 1;
        }

        @include breakpoint-md {
            display: inline-block;
            height: 5vw;
            max-width: 18vw;
            margin-top: 10px;
            margin-bottom: 0;
        }
    }
}
</style>
